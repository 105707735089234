/* html,
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  box-sizing: border-box;
}

body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: black;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

#root {
  width: 100%;
  height: 100%;
} */

.sidebar {
  min-height: auto;
  /* height: 94vh; */
  height: 100%;
  overflow-y: hidden;
  margin: auto;
  border: 1px solid #1e0d61;
  border-radius: 11px;
  width: 4.2rem;
  background-color: #1e0d61;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: 0.3s width;
  /* border: 2px solid green; */
}

.boxicon-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  position: relative;
}

.boxicon {
  margin: 1.1rem;
  animation-iteration-count: 1;
  z-index: 2;
}

.first-and-last-trash-fix {
  margin: 1.1rem;
}

.active {
  background-color: red;
}

.active::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 95%;
  background-color: #fe9a25;
  opacity: 1;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  left: -0.25rem;
  border-top-right-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
} 

.boxicon::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 95%;
  background-color: #fe9a25;
  color: white;
  opacity: 0;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  left: -0.25rem;
  border-top-right-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
}

.boxicon-container:hover > .boxicon::after {
  opacity: 1;
  transition: 0.1s;
}

.sidebar > *:first-child {
  /* margin-top: 1rem; */
  margin-top: 10px;
  /* margin-bottom: 1rem; */
  margin-bottom: 10px;
}
.sidebar > *:last-child {
  margin-top: auto;
  margin-bottom: 2rem;
}

.final-icon {
  background-color: red;
}

.description {
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 4rem;
  z-index: 10;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  color: white;
  transition: 0.2s opacity;
  display: none;
}

.active-description {
  color: white;
}

.expanded {
  width: 19rem;
  align-items: flex-start;
}

.show-description {
  opacity: 1;
  display: block;
}

.container {
  display: flex;
}

.text {
  color: white;
  z-index: 100;
  margin: 0 auto;
  align-self: center;
  font-size: 32px;
}

@media screen and (max-height: 550px) {
  .boxicon {
    margin-left: 1.2rem;
  }
  .first-and-last-trash-fix {
    margin-left: 1.2rem;
  }
}
