#phone_flag{
	width: 0;
	margin-right: 6px;
}

#phone_flag_internal{
	width: 0;
	margin-right: 6px;
}

.MuiSelect-icon{
	opacity: 0;
}

.readClass{
	background: #ccc;
}

.note{
	color: red;
}