.MuiDataGrid-columnHeader {
    background-color: #1e0d61;
    color: #fe9a25;
    font-size: 18px;
    font-weight: 700;
  }
  .MuiDataGrid-cell:focus {
    outline: none !important;
  }
  .MuiDataGrid-checkboxSelection {
    background-color: red;
  }
  .MuiDataGrid-selectionCount {
    display: none;
}
.css-8o8wnm-MuiButtonBase-root-MuiCheckbox-root, .css-cmhqr7{
  color: rgb(60, 195, 245) !important;
}