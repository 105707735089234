
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap");


.App {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 20px;
  max-width: 100%;
  width: 100%;
  max-height: 100vh;
  height: 100vh;
  margin: auto;
  /* padding: 10px 15px; */
  /* background-color: rgba(190, 49, 49, 0.9); */
  /* border: 2px solid green; */
} 

.hashLoader {
  text-align: "center";
  display: flex;
  justify-content: "center";
  align-items: "center";
  width: 100%;
  height: 100vh;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(226, 211, 211);
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(158, 157, 157);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #807f7f;
}


.reusable-icon {
  font-size: 18px !important; /* Adjust font-size as needed */
}


.ck-editor__editable_inline {
  min-height: 200px;
  width:auto
}